import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";
//import Project from "../views/Project.vue";
Vue.use(VueRouter);

function getSitemapProjectsList(data) {
    let ret = [];
    data.forEach((project) => {
        ret.push({ project_name: project.name, title: project.title });
    });
    return ret;
}
import projectsData from "../projects.json";
import casestudiesData from "../casestudies.json";

export const routes = [{
        path: "/",
        name: "Home",
        component: () =>
            import ("../views/Home.vue"),
    },
    {
        path: "/about",
        name: "About",

        component: () =>
            import ("../views/About.vue"),
    },
    {
        path: "/projects/:project_name",
        name: "Project",
        component: () =>
            import ("../views/Project.vue"),
        meta: {
            sitemap: {
                // Meta properties on parent will be
                // inherited by their children
                changefreq: "monthly",
                priority: 0.7,
                slugs: getSitemapProjectsList(projectsData),
            },
        },
    },
    {
        path: "/casestudies/:project_name",
        name: "Casestudy",
        component: () =>
            import ("../views/Project.vue"),
        meta: {
            sitemap: {
                // Meta properties on parent will be
                // inherited by their children
                changefreq: "monthly",
                priority: 0.7,
                slugs: getSitemapProjectsList(casestudiesData),
            },
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default router;