<template lang="">
  <div id="#site-footer">
    <article class="site-footer">
      <section>
        <b-row>
          <b-col>
            <b-row>
              <p><b>Reza Zokaei</b></p></b-row
            >
            <b-row>
              <p><b>Email: </b>reza@rezolution.com</p></b-row
            >
            <b-row
              ><p><b>Phone: </b> (408) 666-6739</p></b-row
            >
            <b-row
              ><p><b>Location: </b> San Jose, CA</p>
            </b-row>
          </b-col>

          <b-col>
            <b-row>
              <blockquote class="card-blockquote">
                <p>
                  “Daring ideas are like chessmen moved forward; they may be
                  beaten, but they may start a winning game.”
                </p>
                <footer class="blockquote-footer">Johann Goethe</footer>
              </blockquote>
            </b-row>
          </b-col>
        </b-row>
      </section>
    </article>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss">
.site-footer {
  display: flex;
  justify-content: left;
  padding: 5rem;

  background: linear-gradient(
      to top,
      var(--gradeinet-start),
      var(--gradeinet-end)
    ),
    url("./../assets/headerbackground1.jpg") no-repeat center center;
  color: white;
  background-size: cover;
}
</style>
