<template lang="">
  <div id="site-header" v-if="showHeader()">
    <article class="site-header">
      <h1 @click="$router.push('/')">Reza Zokaei</h1>
      <blockquote class="card-blockquote">
        <p>
          "Creativity comes from trust. Trust your instincts. And never hope
          more than you work."
        </p>
        <footer class="blockquote-footer">
          <small> Rita Mae Brown </small>
        </footer>
      </blockquote>
      <b-button
        v-if="currentRouteName != 'About'"
        @click="$router.push('about')"
        variant="warning"
        class="button-header"
        >View Resume</b-button
      >
    </article>
  </div>
</template>
<script>
const showHeaderPages = ["Home", "About"];
export default {
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    showHeader() {
      if (showHeaderPages.includes(this.currentRouteName)) return true;
      return false;
    },
  },
};
</script>
<style lang="scss">
.site-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  vertical-align: top;
  background: linear-gradient(to top, #000118, #00000023),
    url("./../assets/headerbackground4.jpg") no-repeat top center;
  // background: url("./../assets/headerbackground2.jpg") center center;
  background-size: cover;
  padding: 0 10px;
  min-height: 50vh;
  width: 100%;

  color: azure;
  h1 {
    font-size: 3rem;
  }
  @media screen and (max-width: 300px) {
    h1 {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 450px) {
    h1 {
      font-size: 3rem;
    }
  }
  @media screen and (min-width: 900px) {
    h1 {
      font-size: 5rem;
    }
  }

  span {
    display: block;
    padding-bottom: 25px;
  }
  button {
    display: block;
    font-size: 1.25rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .blockquote-footer:before {
    content: "\2014\00A0";
  }
}
</style>
