import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import projectsData from "../projects.json";
import casestudiesData from "../casestudies.json";

export default new Vuex.Store({
  state: {
    workTypes: {
      projects: { typeName: "Projects", data: projectsData },
      casestudies: {
        typeName: "Case Studies and Side Projects",
        data: casestudiesData,
      },
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
