import Vue from "vue";
import Vuex from "vuex";

import store from "../store";
Vue.prototype.$store = store;
let api_instance;

Vue.config.productionTip = false;

Vue.use(Vuex);

export const getInstance = () => api_instance;
export const auth_instance = ({ ...options }) => {
  if (api_instance) return api_instance;
  api_instance = new Vue({
    data() {
      return {
        pass: options.pass,
        error: null,
      };
    },
    methods: {},
  });
  return api_instance;
};

export const AuthPlugin = {
  install(Vue, options) {
    Vue.prototype.$api = auth_instance(options);
  },
};
