/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import LazyYoutube from "vue-lazytube";
import VueGtag from "vue-gtag";
import { AuthPlugin } from "./auth";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import AOS from "aos";
import "aos/dist/aos.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(LazyYoutube);
Vue.config.productionTip = false;

// Configuration VueAnalytics
if (process.env.NODE_ENV === "production") {
    Vue.use(
        VueGtag, {
            config: { id: "G-RQWBTVGFH0" },
            pageTrackerTemplate(to, params) {
                return {
                    page_title: params.project_name,
                    page_path: to.path,
                };
            },
        },
        router
    );
}
Vue.mixin({
    methods: {
        updatePageTitle: function(title) {
            let newTitle = process.env.VUE_APP_TITLE;
            if (title) newTitle += " | " + title;
            document.title = newTitle;
        },
        updateTags: function() {},
    },
});

Vue.use(AuthPlugin, { store });

new Vue({
    router,
    store,
    created() {
        AOS.init();
    },
    render: (h) => h(App),
}).$mount("#app");