<template>
  <div id="app" :style="cssProps">
    <div v-if="$route.name !== 'Home'" class="home-link">
      <b-button
        variant="link"
        @click="$router.push('/')"
        size="lg"
        v-b-tooltip.hover
        title="Return home"
      >
        <b-icon icon="house"> </b-icon>
      </b-button>
    </div>
    <div class="share-link">
      <b-button
        variant="link"
        :href="generate_share_email()"
        size="lg"
        v-b-tooltip.hover
        title="Share this page"
      >
        <b-icon icon="share"> </b-icon>
      </b-button>
    </div>

    <site-header />
    <router-view :key="$route.fullPath" />
    <site-footer />
  </div>
</template>
<script>
import SiteHeader from "./components/SiteHeader.vue";
import SiteFooter from "./components/SiteFooter.vue";

export default {
  components: { SiteHeader, SiteFooter },
  data() {
    return {
      gradientStart: "#00155cbb",
      gradientEnd: "#333f61",
      headerIconColor: "#ccc",
    };
  },
  watch: {
    $route: {
      handler() {
        this.updatePageTitle();
      },
    },
  },
  computed: {
    cssProps() {
      return {
        "--gradeinet-start": this.gradientStart,
        "--gradeinet-end": this.gradientEnd,
        "--header-icon-color": this.headerIconColor,
      };
    },
  },
  methods: {
    generate_share_email() {
      let share_email = "mailto:?";
      share_email += "subject= Sharing Reza Zokaei Portfolio Site";
      share_email +=
        "&body=Hello, %0D%0A%0D%0A Please take a look at the page below:%0D%0A%0D%0A";
      share_email += window.location.href;
      share_email += "%0D%0A%0D%0AThanks";
      return share_email;
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500&display=swap");

#app {
  font-family: Poppins, sans-serif, Avenir, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.home-link,
.share-link {
  z-index: 100;

  display: flex;
  justify-content: left;
  padding-top: 0.5rem;
  position: fixed;
  cursor: pointer;
  .b-icon {
    color: var(--header-icon-color);
  }
}
.home-link {
  padding-left: 1rem;
  position: fixed;
}
.share-link {
  right: 0;
  justify-content: right;
  padding-right: 1rem;
}
</style>
